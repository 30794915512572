// 租户管理
import request from '@http';

// 租户列表
export function getLeasorList(params) {
    return request({
        method: 'GET',
        url: '/api/gzf/baseinfo/leasor/',
        params
    })
}

//2022/04/02 王江毅 通过证件号获取租户信息
export function getLeasorDataByCardId(params) {
    return request({
        method: 'GET',
        url: '/api/gzf/rent/user/leasor',
        params
    })
}

//2022/04/20 王江毅 获取用户充值记录
export function depositLog(params) {
    return request({
        method: 'GET',
        url: `/api/gzf/baseinfo/leasor/${params.uuid}/deposit/log`,
        params
    })
}

//2022/05/09 王江毅 修改租户信息
export function updateLeasor(data) {
    return request({
        method: 'PUT',
        url: `/api/gzf/baseinfo/leasor/${data.uuid}/updateLeasor`,
        data
    })
}

//2022/05/10 王江毅 查询租户合同房源
export function getLeasorContracts(params) {
    return request({
        method: 'get',
        url: `/api/gzf/baseinfo/leasor/${params.uuid}/contracts`,
        params
    })
}
// 2022/05/26 王江毅 租户导出
export function leasorExport(params) {
    return request({
        method: 'GET',
        url: '/api/gzf/baseinfo/leasor/exportleasor',
        responseType: 'blob',
        params
    })
}

// 2022/06/16 王江毅 获取租户流水列表
export function balanceWaterList(params) {
    return request({
        method: 'get',
        url: `/api/gzf/balance/water/list`,
        params
    })
}

// 2022/06/22 王江毅 查询租户下所有账单
export function byleasorList(params) {
    return request({
        method: 'get',
        url: `/api/gzf/paybill/byleasor/list`,
        params
    })
}
//2022/06/22 王江毅 租户账单列表导出
export function byleasorExport(params) {
    return request({
        method: 'GET',
        url: '/api/gzf/paybill/byleasor/export',
        responseType: 'blob',
        params
    })
}
//2023/04/13 王珂 获取租户所有的水电余额信息 多个房间
export function balance(params) {
    return request({
        method: 'get',
        url: `/api/gzf/water/elect/leasor/info`,
        params,
    });
}
//2023/04/13 王珂 获取租户余额扣款记录(水电) 多个房间
export function dailyUsage(params) {
    return request({
        method: 'get',
        url: `/api/gzf/water/elect/daily/usage`,
        params,
    });
}

// 2023/04/17 王珂 消息推送管理
export function message(params) {
    return request({
        method: 'get',
        url: `/api/gzf/water/elect/message/page`,
        params,
    });
}
// 2023/04/17 王珂 重新下发
export function reissue(params) {
    return request({
        method: 'get',
        url: `/api/gzf/water/elect/message/rePublish`,
        params,
    });
}
// 2023/04/18 王珂 所有消息类型
export function messageType(params) {
    return request({
        method: 'get',
        url: `/api/gzf/water/elect/message/type`,
        params,
    });
}

// 2023/05/10 王珂 报修管理
export function repairManagement(params) {
    return request({
        method: 'get',
        url: `/api/repair/leasor/repairApplyList`,
        params,
    });
}

// 2023/5/11 王珂 报修记录附件
export function repairInfo(params) {
    return request({
        method: 'get',
        url: `/api/repair/repairApplyImageList`,
        params,
    });
}

// 2023/09/18 王江毅 余额数据异常时提交修改
export function balanceUpdate(params) {
    return request({
        method: 'get',
        url: `/api/gzf/water/elect/balance/update`,
        params,
    });
}

// 2023/09/18 王江毅 每日抄表记录数据异常时修改提交
export function dailyUsageUpdate(params) {
    return request({
        method: 'get',
        url: `/api/gzf/water/elect/daily/usage/update`,
        params,
    });
}

// 2023/09/18 王江毅 手动补充水电费余额账户
export function supplementBalance(params) {
    return request({
        method: 'get',
        url: `/api/gzf/water/elect/manually/supplement/balance`,
        params,
    });
}

// 2023/09/18 王江毅 根据租户查询合同
export function getContractListByLeasor(params) {
    return request({
        method: 'get',
        url: `/api/gzf/contract/leasor/list`,
        params,
    });
}

// 2023/09/18 王江毅 水电催收
export function waterElectReminder(params) {
    return request({
        method: 'get',
        url: `/api/gzf/water/elect/reminder/elect`,
        params,
    });
}

// 2023/09/18 王江毅 水电开关表
export function openCloseMeter(params){
    return request({
        method: 'get',
        url: `/api/gzf/water/elect/close/open/meter`,
        params,
    });
}


// 2024-1-8-陈守亮-抬头
export function risedialogapi(uuid,data){
    return request({
        method: 'POST',
        url: `/api/gzf/leasor/invoice/title/${uuid}`,
        data,
    });
}
// 2024-1-8-陈守亮-抬头详情
export function risedialogapixiangqing(uuid){
    return request({
        method: 'get',
        url: `/api/gzf/echo/invoice/title/${uuid}`,
    });
}

// 2024/04/02 张晓瑜新增押金余额转移获取押金金额
export function getDepositAmountapi(params){
    return request({
        method: 'get',
        url: `/api/gzf/baseinfo/leasor/depo/transfer/balance/amount`,
        params,
    });
}
// 2024/04/02 张晓瑜新增 押金余额转房租余额
export function SaveDepositbalanceapi(params){
    return request({
        method: 'get',
        url: `/api/gzf/baseinfo/leasor/depo/transfer/rental/balance`,
        params,
    });
}

// 2024/04/03 张晓瑜新增 水电余额转移
export function SaveWaterElectBanlanceapi(params){
    return request({
        method: 'get',
        url: `/api/gzf/water/elect/transfer/banlance`,
        params,
    });
}

//2024-4-7-陈守亮-转移记录列表查询
export function transferRecordListapi(params){
    return request({
        method: 'get',
        url: `/api/gzf/bill/transfer/record/list`,
        params,
    });
}

//2024-7-20-水电充值-陈守亮
export function transferTopupapi(uuid,data){
    return request({
        method: 'POST',
        url: `/api/gzf/baseinfo/leasor/topup/${uuid}/balance`,
        data,
    });
}

// 2024/08/23 张晓瑜新增租户管理-黑名单列表
export function getBlacklistAPI(params) {
    return request({
        method: 'GET',
        url: 'api/gzf/baseinfo/leasor/blacklist/list',
        params
    })
}
// 2024/08/26 张晓瑜新增租户管理-黑名单-新增黑名单
export function getBlackAddAPI(params) {
    return request({
        method: 'GET',
        url: 'api/gzf/baseinfo/leasor/blacklist/create',
        params
    })
}
// 2024/08/26 张晓瑜新增租户管理-黑名单-撤退黑名单
export function getBlackRevokeAPI(params) {
    return request({
        method: 'GET',
        url: 'api/gzf/baseinfo/leasor/blacklist/cancel',
        params
    })
}