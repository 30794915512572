import request from '@http';

// 2022/05/23 王江毅 资产列表
export function getAssetsList(params) {
    return request({
        method: 'GET',
        url: '/api/assets/list',
        params
    })
}

// 2022/05/23 王江毅 新建入库
export function publish(data) {
    return request({
        method: 'PUT',
        url: '/api/assets/publish',
        data
    })
}

// 2022/05/24 王江毅 分类资产列表
export function getAssetsListByAssetsCode(assetsCode) {
    return request({
        method: 'post',
        url: `/api/assets/list/${assetsCode}`
    })
}

// 2022/05/24 王江毅 资产分配
export function distribute(data) {
    return request({
        method: 'post',
        url: `/api/assets/distribute`,
        data
    })
}

// 2022/05/24 王江毅 获取房间内设备分类数据
export function getAssetsByCodeandUuid(code,uuid) {
    return request({
        method: 'get',
        url: `api/assets/list/${code}/${uuid}`
    })
}
// 2022/05/24 王江毅 资产报修
export function report(data) {
    return request({
        method: 'post',
        url: `/api/assets/report`,
        data
    })
}

// 2022/06/02 王江毅 资产标签下载
export function print(uuid) {
    return request({
        method: 'post',
        url: `/api/assets/getTag/${uuid}/print`,
        responseType: 'blob',
    })
}

// 2022/06/06 王江毅 资产无分页列表 companyStockUuid仓库uuid
export function getAssetsNolist(params) {
    return request({
        method: 'get',
        url: `/api/assets/nolist`,
        params
    })
}

// 2023/05/16 王江毅 资产标签批量导出
export function getTagListPrint(params) {
    return request({
        method: 'get',
        url: `/api/assets/getTag/list/print`,
        params
    })
}

// 2023/05/18 王江毅 资产入库列表 - 一级
export function getParentListPage(params) {
    return request({
        method: 'get',
        url: `/api/assets/parent/list/page`,
        params
    })
}

// 2023/05/18 王江毅 资产导出
export function getListExport(params) {
    return request({
        method: 'get',
        url: `/api/assets/list/export`,
        params,
        responseType: 'blob',
    })
}
// 2023/05/18 王江毅 资产详情 盘点列表
export function getAssetsInventoryList(params) {
    return request({
        method: 'get',
        url: `/api/assets/inventory/list`,
        params,
    })
}

// 2023/05/31 王江毅 资产回收
export function recycle(params) {
    return request({
        method: 'get',
        url: `/api/assets/details/recycle`,
        params,
    })
}

// 2023/05/31 王江毅 资产回收
export function allocate(params) {
    return request({
        method: 'get',
        url: `/api/assets/details/allocate`,
        params,
    })
}

// 2023/05/31 王江毅 资产删除 /api/assets/details/delete
export function detailsDelete(params) {
    return request({
        method: 'get',
        url: `/api/assets/details/delete`,
        params,
    })
}
// 2023/06/07 王江毅 资产入库一级页面列表 /api/assets/type/list
export function getAssetsTypeList(params) {
    return request({
        method: 'get',
        url: `/api/assets/type/list`,
        params,
    })
}

// 2023/06/08 王江毅 资产入库二级页面列表 修改
export function assetsUpdate(data) {
    return request({
        method: 'put',
        url: `/api/assets/update`,
        data
    })
}

// 2023/06/08 王江毅 获取房间资产
export function getApartmentAssetsList(params) {
    return request({
        method: 'get',
        url: `/api/apartment/assets/list`,
        params,
    })

}
// 2023/06/08 王江毅 资产入库二级页面列表 删除
export function assetsTwoDelete(params) {
    return request({
        method: 'DELETE',
        url: `/api/assets/delete`,
        params,
    })
}

// 2023/08/14 张晓瑜 资产入库三级页面列表 批量删除
export function batchDeleteApi(data){
    return request({
        method:'POST',
        url:`/api/assets/details/delete/list`,
        data
    })
}
// 2023/08/14 张晓瑜 资产入库三级页面列表 批量删除
export function batchGetApi(params){
    return request({
        method:'get',
        url:`/api/gzf/data/code`,
        params
    })
}
